import { cn } from "../utils";
import { container } from "./container.styles";

interface ContainerProps {
  children?: React.ReactNode;
  className?: string;
}

export const Container = ({ className, ...props }: ContainerProps) => {
  return <div className={cn(container.root(), className)} {...props} />;
};
